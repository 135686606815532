import convertSeconds from 'convert-seconds'

export default ({ total, processed, oldProcessed }, interval) => {
    const packet = processed - oldProcessed

    const remain = total - processed;

    if (packet <= 0) return "Infinite, No new Data processed!"
    const leftTime = packet > 0 ? (remain / packet) * (interval / 1000) : 0;
    //{ hours, minutes, seconds }
    console.log(remain, packet)
    if (isNaN(leftTime)) return "Unknown"

    const res = convertSeconds(leftTime)
    return Object
        .entries(res)
        .filter(([key, value]) => value)
        .map(([key, value]) => `${value} ${key}`).join(' & ')
}