import React, { useState } from 'react'
import { Layouts } from '../../components'
import './style.css'
import { Button } from '../../components/Buttons'
import { request } from '../../utils/network'
import config from '../../config'

const { authRedirectUrl } = config

const { Page, FlexBox } = Layouts

const DonationPage = props => {
    const [loading, setLoading] = useState(false)

    const getAuthRedirect = async () => {
        const options = {
            uri: `/v1/authorize?redirect=${authRedirectUrl}`,
        }
        setLoading(true)
        const data = await request(options)
        setLoading(false)

        if (data.code === 200)
            window.open(data.redirect, '_self')
    }

    return (
        <Page className='donation-page'>
            <FlexBox wrappable center='v-center h-center'>
                <FlexBox
                    column
                    center='h-center'
                    className='call-to-action-content white-text text-center max-width-600'
                >
                    <h1 className='quote-text'>
                        Save what you like on the web to you could directly without downloading it to your local machine
                    </h1>
                    <div className='large-text margin-v-20'>
                        upload files from the clouds directly to yours(google drive cloud)
                    </div>
                    <div>

                        <Button
                            className='big'
                            onprogress={loading}
                            onClick={getAuthRedirect}
                        >
                            Start Transferring
                        </Button>
                    </div>
                </FlexBox>
            </FlexBox>
        </Page>
    )
}

DonationPage.propTypes = {

}

export default DonationPage
