import React, { useState } from 'react'
import { Layouts} from '../../components'
// import Select from 'react-select'
// import { DonationForm } from './components'
import './style.css'
import { Button } from '../../components/Buttons'
import { request } from '../../utils/network'
import { queryString } from '../../utils/helpers'
import Storage from '../../utils/storage'
// import config from '../../config'
import { TextField } from '../../components/Inputs'

import { TransferProcessTracing } from './components'
// const { authRedirectUrl } = config

const { Page, FlexBox } = Layouts

const currentProcesses = Storage.get('processes', [])
window.store = Storage;

const TransferPage = props => {
    const [filePath, setFilePath] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processes, setProcesses] = useState(currentProcesses || [])
    const [error, setError] = useState()

    const { code } = queryString(props.location.search)

    const onChange = ({ target: { name, value } }) => {
        setFilePath(value)
    };

    const startTransfer = async () => {
        const options = {
            uri: `/v1/transfer`,
            method: 'POST',
            body: {
                code,
                fileUrl: filePath
            }
        }

        setLoading(true)
        const data = await request(options)
        setLoading(false)

        if (data.code !== 200) {
            setError(data.message)
        } else {
            console.log(data)
            const newProcesses = [...processes, { id: data.processId }]
            Storage.set('processes', newProcesses)
            setProcesses(newProcesses)
            props.history.push('/transfer')
        }
    }

    const onProcessDelete = (processId) => {
        const newProcesses = processes.filter(proc => proc.id !== processId)
        Storage.set('processes', newProcesses)
        setProcesses(newProcesses)
    }
    const onProcessComplete = (processId) => {
        const newProcesses = processes.map(proc => proc.id === processId ? { ...proc, completed: true } : proc)
        Storage.set('processes', newProcesses)
        setProcesses(newProcesses)
    }
    return (
        <Page className='donation-page'>
            <FlexBox wrappable center='v-center h-center'>
                <FlexBox
                    column
                    center='h-center'
                    className='call-to-action-content white-text text-center max-width-600'
                >

                    <h1 className='quote-text'>
                        Save what you like on the web to you could directly without downloading it to your local machine
                    </h1>
                    <div className='large-text margin-v-20'>
                        upload files from the clouds directly to yours(google drive cloud)
                    </div>
                    <FlexBox center='v-center'>
                        <TextField
                            onChange={onChange}
                            name='targetFile'
                            className='wide-element'
                            disabled={loading}
                        />
                        <Button
                            className='big'
                            disabled={loading}
                            onprogress={loading}
                            onClick={startTransfer}
                        >
                            Start Transferring
                            </Button>
                    </FlexBox>
                    {error && <div className="error-text">{error}</div>}
                    <TransferProcessTracing
                        processes={processes}
                        onProcessDelete={onProcessDelete}
                        onProcessComplete={onProcessComplete}
                    />
                </FlexBox>
            </FlexBox>
        </Page>
    )
}

// <ProgressUpdates />
TransferPage.propTypes = {

}

export default TransferPage
