import React from 'react'
import clx from 'classnames'


const CheckBox = ({
    active,
    onSelect,
    children,
    // value,
    bordered,
    withCheckIcon
}) => {


    const classNames = clx({
        'check-box':true,
        active,
        bordered
    })

    return (
       <div
        className={classNames} 
        onClick={onSelect}
        >
        {withCheckIcon && <input onChange={onSelect} type="checkbox" checked={active} />}
        {children}
       </div>
    )
}

CheckBox.propTypes = {

}
CheckBox.defaultProps = {
    withCheckIcon : true
}

export default CheckBox
