import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import fileSize from 'filesize'

import { request } from '../../../utils/network'
import { getTimeLeft } from '../../../utils/helpers'
import { SlimProgressBar } from '../../../components'
import { FlexBox } from '../../../components/Layouts'

const getProcessProgress = async (id, cb) => {
    if (!id) return { message: "invalid process ID" };

    const options = {
        uri: `/v1/progress/${id}`
    }

    const res = await request(options)
    cb(res)
    return res
}
let interval = 2000;

const ProcessesList = ({ processes = [], ...props }) => {
    return (
        <FlexBox column>
            {processes.map(pros => (
                <ProcessProgressTracking
                    key={pros.id}
                    processId={pros.id}
                    {...props}
                />
            ))}
        </FlexBox>
    )
}

const ProcessProgressTracking = ({ processId, onProcessComplete, onProcessDelete, error, loading }) => {

    const [status, setStatus] = useState({
        percentage: 0,
        total: 0,
        processed: 0
    });

    let oldProcessed = status.processed;

    useEffect(() => {
        if (!error && !loading && processId) {
            const terminate = setInterval(() => {
                getProcessProgress(processId, ({ status, error }) => {
                    console.log(status)
                    if (typeof status === 'object') {
                        status.oldProcessed = oldProcessed

                        setStatus(status)
                        if (status.processed === status.total)
                            onProcessComplete(processId)
                    } else {
                        clearInterval(terminate)
                    }

                })
            }, interval);

            return () => {
                clearInterval(terminate)
            }
        }
    }, [loading, error, processId, oldProcessed, onProcessComplete, onProcessDelete])

    if (!processId) return null


    const timeLeft = getTimeLeft(status, interval)
    return (
        <div className='padding-20 white-bg soft-edges margin-v-10'>
            <SlimProgressBar min={status.processed} max={status.total} className='margin-v-10' />
            <div className="large-title">{`Time Left:${timeLeft}`}</div>
            <br />
            <div className="large-title">{`Completed:${status.percentage}%`}</div>
            <div className="large-title">{`File Size :${fileSize(status.total)}`}</div>
            <div className="large-title">{`Transferred:${fileSize(status.processed)}`}</div>
        </div>
    )
}

ProcessProgressTracking.propTypes = {

}


export default ProcessesList
