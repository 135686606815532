export default async ({
    token: Authorization,
    contentType = 'json',
    method = 'GET',
    body = {},
    uri,
}) => {
    // try {
    let options = {};
    if (contentType === 'json' && method.toLocaleLowerCase() !== 'get') {
        options = {
            method,
            headers: {
                Authorization,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
            body: JSON.stringify(body),
        };
    } else if (contentType === 'json' && method.toLocaleLowerCase() === 'get') {
        options = {
            method,
            headers: {
                Authorization,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
        };
    } else {
        options = {
            method,
            headers: {
                Authorization,
            },
            body,
        };
    }

    const resBody = await fetch(uri, options);
    const response = await resBody.json();

    response.code = resBody.status;
    return response;
    // } catch (err) {
    //   return { success: false, message: `Connection Error ${err.message}` };
    // }
};

