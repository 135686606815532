import React from 'react';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactToolTip from 'react-tooltip'

import {
  Home,
  Transfer
} from './pages'






function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path='/transfer' component={Transfer} />
          <Route exact path='/' component={Home} />
        </Switch>
      </BrowserRouter>
      <ReactToolTip delayShow={300} type='light' />
      <ReactNotification />
    </div>
  );
}

export default App;
