export default {
    firebase: {
        apiKey: "AIzaSyB3Gw_4lK0C4GkGs9yuB2-H0sN81-GHtAE",
        authDomain: "spaseohero.firebaseapp.com",
        databaseURL: "https://spaseohero.firebaseio.com",
        projectId: "spaseohero",
        storageBucket: "spaseohero.appspot.com",
        messagingSenderId: "477829401346",
        appId: "1:477829401346:web:d4f66e18b75a5ca408d01b",
        measurementId: "G-7T8BFRKW9K"
    },
    authRedirectUrl : "https://cloudyme.eslam.dev/transfer",
}